// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import {
  Box, Collapse, Divider, Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

type Props = {
  title: string,
  children: Node,
}
const useStyles = makeStyles((theme) => ({
  collapseTitle: {
    cursor: "pointer",
  },
  title: {
    background: `linear-gradient(to right, ${theme.palette.gradientLight}, ${theme.palette.gradientDefault})`,
    // $FlowFixMe
    "-webkit-background-clip": "text",
    // $FlowFixMe
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
  },
  icon: {
    color: "#fff",
  },
}));

const CollapseCard: StatelessFunctionalComponent<Props> = ({ title, children }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box onClick={handleClick} className={classes.collapseTitle}>
        <Box px={3} py={1} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" className={classes.title}>{title}</Typography>
          {open ? <ExpandLess className={classes.icon} /> : <ExpandMore className={classes.icon} />}
        </Box>
      </Box>
      <Collapse in={open}>
        <Divider />
        {children}
      </Collapse>
    </>
  );
};

export default CollapseCard;
