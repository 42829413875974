// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import {
  Navigate, Routes, Route, useLocation, useNavigate,
} from "react-router-dom";
import {
  Box, Container, CssBaseline, Toolbar,
} from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import type {
  DispatchToProps, OwnProps, Props, StateToProps,
} from "./types/App.types";
import defaultTheme from "../../theme";
import Dashboard from "../Dashboard";
import TransactionReport from "../TransactionReport";
import ActiveBrands from "../ActiveBrands";
import PostbackUrls from "../../components/PostbackUrls";
import Login from "../Login";
import PrivateRoute from "../../components/PrivateRoute";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import { isAuthenticated } from "../../services/request";
import {
  HEADER_TOOLBAR_HEIGHT,
  FOOTER_TOOLBAR_HEIGHT,
} from "../../helpers/constants";

// $FlowFixMe
const mapStateToProps: (*) => StateToProps = () => ({});

// $FlowFixMe
const mapDispatchToProps: (Dispatch<Actions>) => DispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const useStyles = makeStyles(() => ({
  content: {
    flexGrow: 1,
    minWidth: "500px",
  },
  body: {
    maxWidth: "1600px",
    minHeight: `calc(100vh - ${HEADER_TOOLBAR_HEIGHT + FOOTER_TOOLBAR_HEIGHT}px)`,
  },
  toolbar: {
    minHeight: "57px",
  },
  background: {
    backgroundImage: "url(/web-bg.jpeg)",
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center top",
    backgroundSize: "100% 100%",
  },
  backgroundWrapper: {
    display: "block",
    position: "absolute",
    zIndex: -1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const App: StatelessFunctionalComponent<Props> = ({}: Props) => {
  const isUserAuthenticated: boolean = isAuthenticated();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        {!isUserAuthenticated && (
          <div className={classes.backgroundWrapper}>
            <div className={classes.background} />
          </div>
        )}
        <CssBaseline />
        <Box display="flex">
          <Navigation />
          <main
            className={classes.content}
          >
            <Toolbar className={classes.toolbar} />
            <Box component={Container} py={3} className={classes.body}>
              <Routes>
                <Route path="/login" element={<Login location={location} history={navigate} />} />
                <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
                <Route path="/transactionReport" element={<PrivateRoute component={TransactionReport} />} />
                <Route path="/activeBrands" element={<PrivateRoute component={ActiveBrands} />} />
                <Route path="/postbacks" element={<PrivateRoute component={PostbackUrls} />} />
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </Box>
          </main>
        </Box>
        <Footer />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(App);
