// @flow

const themeDefaultObject: mixed = {
  theme: "default",
  props: {},
  typography: {},
  shape: {},
  buttons: {
    main: {},
    secondary: {},
  },
  palette: {
    background: {
      default: "#021A2D",
    },
    text: {
      secondary: "rgba(255, 255, 255, 0.85)",
    },
    primary: {
      light: "#FFD33A",
      main: "#ff9000",
      dark: "#FF9000bb",
    },
    gradientLight: "#FF9000",
    gradientDefault: "#FFD33A",
    logout: "rgba(255, 255, 255, 0.5)",
    policyLinks: "rgba(255, 255, 255, 0.6)",
    mainBg: "rgba(255, 255, 255, 0.08)",
    dialogBg: "#001A2D",
  },
  overrides: {
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "none",
      },
      root: {
        "& *": {
          borderRadius: "0px!important",
        },
      },
    },
    MuiMenuItem: {
      root: {
        "&$selected": {
          borderLeft: "1px solid #FF9000",
          "& .MuiListItemText-root": {
            fontWeight: "bold",
            color: "#fff",
          },
        },
      },
    },
    MuiAutocomplete: {
      root: {
        border: "none",
        background: "rgba(255, 255, 255, 0.08)",
        "& .MuiChip-root": {
          backgroundColor: "rgba(255, 255, 255, 0.08)",
        },
      },
      listbox: {
        color: "#fff",
      },
      paper: {
        borderRadius: "0px",
        background: "#142c3e",
      },
      option: {
        "&:hover": {
          background: "#17354b",
        },
      },
    },
    "MuiTableCell": {
      root: {
        borderBottom: "none",
      },
    },
  },
};

export default themeDefaultObject;
