// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Typography, Button, Dialog, DialogContent, DialogActions,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

export type ChildProps = {
  open: () => void
};

type Props = {
  banner: string,
  title: string,
  open: boolean,
  handleClose: () => void,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.dialogBg,
    borderRadius: "15px",
  },
  container: {
    backgroundColor: theme.palette.mainBg,
  },
  whiteBg: {
    color: "#fff",
  },
  closeBtn: {
    background: `linear-gradient(to right, ${theme.palette.gradientLight}, ${theme.palette.gradientDefault})`,
    color: "#fff",
    fontWeight: "bold",
    margin: theme.spacing(2),
  },
}));

const BannerDialog: StatelessFunctionalComponent<Props> = ({
  banner,
  title,
  open,
  handleClose,
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper, container: classes.container }}
    >
      <DialogContent>
        <img
          width="100%"
          src={banner}
          alt="Banner url"
        />
        <Typography className={classes.whiteBg}>{title}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.closeBtn}
          color="primary"
          size="large"
          onClick={handleClose}
          fullWidth
          data-testid="banner-dialog-close-btn"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BannerDialog;
