// @flow
import React from "react";
import {
  ComposedChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip, Area,
} from "recharts";
import type { ItemData } from "../../services/dashboardApi";

export const formatDate = (v: string): string => v;
export const formatNumber = (v: number): string => (v >= 1000 ? `${v / 1000}k` : String(v));

type Props = {
  data: Array<ItemData>,
}

const getFallbackData: () => { amount: number, date: string }[] = () => [
  { amount: 0, date: "" },
  { amount: 0, date: "" },
];

const Chart = ({ data }: Props) => {
  const commonAreaProps = {
    type: "monotone",
    strokeWidth: 2,
    strokeLinecap: "round",
    activeDot: true,
    isAnimationActive: false,
  };

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={350} minWidth={300}>
      <ComposedChart
        data={data.length ? data : getFallbackData()}
        margin={{
          top: 30, right: 60, left: 0, bottom: 30,
        }}
      >

        <defs>
          <linearGradient id="stroke1">
            <stop stopColor="#FF9000" />
            <stop offset="1" stopColor="#FFD33A" />
          </linearGradient>
          <linearGradient id="stroke2">
            <stop stopColor="#1E88E5" />
          </linearGradient>
          <linearGradient id="stroke3">
            <stop stopColor="rgba(255, 255, 255, 0.6)" />
          </linearGradient>
        </defs>

        <CartesianGrid vertical={false} />
        <Tooltip labelFormatter={formatDate} />
        <XAxis dataKey="date" tickFormatter={formatDate} axisLine={false} stroke="url(#stroke3)" />
        <YAxis tickFormatter={formatNumber} axisLine={false} stroke="url(#stroke3)" />
        <Legend
          wrapperStyle={{
            top: 15,
            left: 0,
            color: "#fff",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
          verticalAlign="top"
          align="center"
        />

        <Area {...commonAreaProps} dataKey="amount" name="Commission" stroke="url(#stroke1)" />
        <Area {...commonAreaProps} dataKey="quantity" name="Leads" stroke="url(#stroke2)" />

      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Chart;
