// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import { Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExitToApp } from "@mui/icons-material";
import { LogoLight } from "../../icons";
import type { Props } from "./types/Header.types";
import { isAuthenticated } from "../../services/request";

const useStyles = makeStyles((theme) => ({
  layout: {
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    backgroundColor: "rgba(255, 255, 255, 0)",
    width: "100%",
    borderBottom: isAuthenticated() ? `solid 1px ${theme.palette.mainBg}` : "none",
  },
  logo: {
    margin: "auto",
  },
  icons: {
    color: theme.palette.logout,
  },
}));

const Header = ({
  isShowAction, onLogout,
}: Props) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes && classes.layout}>
        <Box
          p={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className={classes && !isShowAction && classes.logo} display="flex" alignItems="left" mr={1} ml={1}>
            <LogoLight />
          </Box>

          {isShowAction && (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box
                data-testid="action_icons"
                className={classes && classes.iconsRoot}
                mr={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton name="logout" onClick={onLogout} className={classes && classes.icons} size="small">
                  <ExitToApp />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Header;
