// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { useLocation } from "react-router-dom";
import { isAuthenticated, logout } from "../../services/request";
import Header from "../Header";
import AppNavSidebar from "../AppNavSidebar";
import { redirect } from "../../helpers/generators";

type Props = {}

const Navigation: StatelessFunctionalComponent<Props> = () => {
  const isShowAction: boolean = isAuthenticated();
  useLocation();

  return (
    <>
      <Header
        isShowAction={isShowAction}
        onLogout={() => {
          logout();
          redirect("/");
        }}
      />
      {isShowAction && (
        <AppNavSidebar
          isOpen
        />
      )}
    </>
  );
};

export default Navigation;
