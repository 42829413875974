// @flow
import React, { type StatelessFunctionalComponent, useState } from "react";
import {
  Box, Card, Container, Typography, type Theme,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { isAuthenticated, setAuthToken } from "../../services/request";
import AuthField from "./AuthField";
import SelectProductCompanyField from "./SelectProductCompanyField";
import { setAuthDataSaga } from "../../actions/auth";
import type { Classes } from "./types/Login.types";

type Props = {
  location: Object,
  history: *,
  onLogin: typeof setAuthDataSaga,
}

const useStyles: () => Classes = makeStyles((theme: Theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  card: {
    background: theme.palette.background.default,
    border: "none",
    boxShadow: "none",
  },
  title: {
    background: `linear-gradient(to right, ${theme.palette.gradientLight}, ${theme.palette.gradientDefault})`,
    // $FlowFixMe
    "-webkit-background-clip": "text",
    // $FlowFixMe
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold !important",
    paddingBottom: theme.spacing(4),
  },
}));

const Login: StatelessFunctionalComponent<Props> = ({ location, history, onLogin }: Props) => {
  const classes = useStyles();
  const [loginData, setLoginData] = useState<{token: string, expires: number} | void>();

  if (isAuthenticated()) {
    const { from } = location.state || { from: { pathname: "/" } };
    return <Navigate to={from} />;
  }

  const handleSubmit = (companies: string[]) => {
    if (loginData) {
      const { from } = location.state || { from: { pathname: "/" } };
      onLogin({
        history,
        companies,
        redirect: from.pathname,
        expires: new Date(loginData.expires * 1000),
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <Card className={classes.card}>
        <Box display="flex" flexDirection="column" alignItems="center" p={2}>
          <Typography variant="h2" className={classes.title}>
            Log In
          </Typography>
          { !loginData ? (
            <AuthField onNext={(data: {expires: number, token: string}) => {
              setAuthToken(data.token, new Date(data.expires * 1000));
              setLoginData(data);
            }}
            />
          ) : (
            <SelectProductCompanyField onNext={handleSubmit} />
          )}
        </Box>
      </Card>
    </Container>
  );
};

export default Login;
