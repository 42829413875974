// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import {
  AppNavSidebar as AppNavSidebarComponent,
  AppNavSidebarMenuComponent,
  type AppNavSidebarMenuComponentUrl,
  type AppNavSidebarProps,
} from "@fas/cpa-cabinet-ui";
import { type Theme, Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Assessment, Home, Star } from "@mui/icons-material";
import PostbackUrl from "../../icons/PostbackUrl";

type Props = {
  isOpen: boolean,
}

const useDrawerStyles: () => $PropertyType<AppNavSidebarProps, "classes"> = makeStyles((theme): $PropertyType<AppNavSidebarProps, "classes"> => ({
  // $FlowFixMe
  paper: {
    justifyContent: "flex-start",
    background: theme.palette.background.default,
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    minHeight: theme.spacing(6),
  },
  listComponent: {
    paddingTop: theme.spacing(4),
    background: theme.palette.background.default,
  },
  listItemText: {
    color: theme.palette.logout,
    textTransform: "uppercase",
  },
  listItemIcon: {
    color: theme.palette.gradientLight,
  },
}));

const AppNavSidebar: StatelessFunctionalComponent<Props> = ({
  isOpen,
}: Props) => {
  const drawerClasses: $PropertyType<AppNavSidebarProps, "classes"> = useDrawerStyles();
  const classes = useStyles();
  const links: Array<AppNavSidebarMenuComponentUrl> = [
    { url: "/dashboard", text: "Dashboard", icon: <Home /> },
    { url: "/transactionReport", text: "Report", icon: <Assessment /> },
    { url: "/activeBrands", text: "Active Brands", icon: <Star /> },
    { url: "/postbacks", text: "Tracking Tools", icon: <PostbackUrl /> },
  ];

  const Offset = () => <Toolbar className={classes.toolbar} />;

  return (
    <AppNavSidebarComponent
      permanentIconsVisible
      isOpen={isOpen}
      classes={drawerClasses}
    >
      <Offset />
      <AppNavSidebarMenuComponent
        links={links}
        classes={{
          listComponent: classes.listComponent,
          menuItemIcon: classes.listItemIcon,
          menuItemText: classes.listItemText,
        }}
      />
    </AppNavSidebarComponent>
  );
};

export default AppNavSidebar;
