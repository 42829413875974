// @flow
import type { Saga } from "redux-saga";
import { call, takeEvery } from "redux-saga/effects";
import { setAuthData } from "../../services/request";
import { getDropdownList } from "../../services/dictionariesApi";
import { SET_AUTH_DATA_SAGA } from "../../helpers/constants";
import type { SetAuthDataSaga } from "../../actions/auth";
import { PERFORMER } from "../../store";

export function* makeFetch(action: SetAuthDataSaga): Saga<void> {
  try {
    const {
      companies,
      redirect,
      expires,
    }: SetAuthDataSaga = action;

    const { data: { data } } = yield call(getDropdownList, "departmentsByMarketingCompany", PERFORMER);

    const departments: string[] = data.map(({ value }: { value: string }): string => value);

    setAuthData(companies, departments, expires);

    window.location = redirect;
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(SET_AUTH_DATA_SAGA, (makeFetch: Function));
}
