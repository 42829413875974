// @flow
import defaultObject from "./default";
import themeV5 from "./themeV5";

const themeObject = {
  defaultObject,
  themeV5,
};

export default themeObject;
