// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent, useEffect, useState,
} from "react";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import {
  Box, Button, TextField, Typography, InputAdornment,
} from "@mui/material";
import { Autocomplete } from "@mui/lab";
import { connect } from "react-redux";
import { RecentActors, ArrowDropDown } from "@mui/icons-material";
import { getDictionarySaga } from "../../actions/dictionaries";
import type { GetDictionarySaga } from "../../actions/dictionaries";
import { getDictionary } from "../../selectors/dictionaries";
import type { Actions, State } from "../../store";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { makeStylesTyped } from "../../helpers/generators";
import { PERFORMER } from "../../store";

export type OwnProps = $ReadOnly<{|
  onNext: (string[]) => mixed,
|}>;

export type StateToProps = $ReadOnly<{|
  productCompaniesDropdown: DropDownObjItemType[],
|}>;

export type DispatchToProps = $ReadOnly<{|
  onGetProductCompaniesDropdownData: () => mixed,
|}>;

export type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
|}>;

const mapStateToProps: (state: State) => StateToProps = (state) => ({
  productCompaniesDropdown: getDictionary(state, "productCompany"),
});

const mapDispatchToProps: (Dispatch<Actions>) => DispatchToProps = (dispatch) => bindActionCreators({
  onGetProductCompaniesDropdownData: (): GetDictionarySaga => getDictionarySaga("productCompany", { performer: PERFORMER }),
}, dispatch);

const useStyles = makeStylesTyped((theme) => ({
  inputLabel: {
    color: "#fff",
    textAlign: "center",
    marginBottom: theme.spacing(0.5),
  },
  loginBtn: {
    background: `linear-gradient(to right, ${theme.palette.gradientLight}, ${theme.palette.gradientDefault})`,
    color: "#fff",
  },
  inputStartAdornment: {
    color: theme.palette.gradientDefault,
  },
  inputEndAdornment: {
    color: theme.palette.gradientDefault,
    "&:hover": {
      cursor: "pointer",
    },
  },
  inputText: {
    color: "#FFF",
    paddingLeft: theme.spacing(1),
  },
  autocompleteRoot: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    borderLeft: `3px solid ${theme.palette.gradientDefault}`,
  },
  inputRoot: {
    "& input": {
      color: "#FFF",
      padding: "3px 4px 3px 6px",
    },
  },
}));

const SelectProductCompanyField: StatelessFunctionalComponent<Props> = ({
  onNext,
  productCompaniesDropdown,
  onGetProductCompaniesDropdownData,
}: Props) => {
  const classes = useStyles();
  const [company, setCompany] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    onGetProductCompaniesDropdownData();
  }, []);

  useEffect(() => {
    const list = productCompaniesDropdown.map(({ label }) => ({ label, value: label }));
    setOptions([{ label: "All", value: list.map(({ label }) => label) }, ...list]);
  }, [productCompaniesDropdown]);

  const handleSubmit = () => {
    if (company) {
      onNext(Array.isArray(company.value) ? company.value : [company.value]);
    }
  };

  return (
    <>
      <Typography variant="body2" className={classes.inputLabel}>
        Choose Company
      </Typography>
      <Autocomplete
        id="combo-box"
        className={classes.autocompleteRoot}
        options={options}
        value={company}
        onChange={(e, value) => setCompany(value)}
        getOptionLabel={(option) => String(option.label)}
        getOptionSelected={(o, v) => o.value === v.value}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.inputRoot}
            variant="outlined"
            required
            fullWidth
            size="small"
            name="company"
            placeholder="Choose from the list"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start" className={classes.inputStartAdornment}>
                  <RecentActors />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" className={classes.inputEndAdornment} onMouseDown={params.inputProps.onMouseDown}>
                  <ArrowDropDown />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <Button
        className={classes.loginBtn}
        fullWidth
        variant="contained"
        color="primary"
        name="loginButton"
        onClick={handleSubmit}
        disabled={!company}
      >
        <Box width="100%">Log In</Box>
      </Button>
    </>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(SelectProductCompanyField);
