// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node } from "react";
import { BrowserRouter } from "react-router-dom";
import AppContainer from "../../containers/App";

function App(): Node {
  return (
    <BrowserRouter>
      <AppContainer />
    </BrowserRouter>
  );
}

export default App;
