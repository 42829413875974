// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import {
  Box, Button, CircularProgress, Grid, Typography,
} from "@mui/material";
import { FilterBuilder } from "@fas/cpa-cabinet-ui";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table/actions";
import dayjs from "dayjs";
import en from "dayjs/locale/en-au";
import { GetApp } from "@mui/icons-material";
import type { Column } from "../TableComponent/types/TableComponent.types";
import { TRANSACTION_TABLE } from "../../helpers/constants";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { TableComponent, TablePagination } from "../TableComponent";
import type { ItemData, TotalData } from "../../services/dashboardApi";
import { makeStylesTyped, mapOptions, presets } from "../../helpers/generators";
import { setQueryFilter } from "../../helpers/queryFilter";

const FilterWrapper: StatelessFunctionalComponent<*> = ({ children }) => (
  <Box display="inline-block" pr={1} pb={1}>{children}</Box>
);

type Props = {|
  data: ItemData[],
  totalData: TotalData,
  page: number,
  total: number,
  pageSize: number,
  filters: Filters,
  loading: boolean,
  onChangeTablePage: (string, number) => mixed,
  onChangeTableFilters: (string, Filters) => mixed,
  onChangeTablePageSize: (string, number) => mixed,
  onGetData: () => mixed,
  onDownloadTransactionReport: () => mixed,
  departmentsDropdown: DropDownObjItemType[],
  countriesDropdown: DropDownObjItemType[],
  customersDropdown: DropDownObjItemType[],
  platformsDropdown: DropDownObjItemType[],
  agesDropdown: DropDownObjItemType[],
  onGetCountriesDropdownData: () => mixed,
  onGetPlatformsDropdownData: () => mixed,
  onGetAgesDropdownData: () => mixed,
  setIsAdvancedFilterEnabled: (boolean) => mixed,
  isAdvancedFilterEnabled: boolean,
  fields: string[],
|}

const useStyles = makeStylesTyped((theme) => ({
  input: {
    width: "180px",
    background: theme.palette.mainBg,
    color: "#fff",
    borderRadius: "4px",
  },
  whiteBg: {
    "& *": {
      color: theme.palette.text.secondary,
    },
  },
  dateRange: {
    width: "253px",
    color: "#fff",
    background: theme.palette.mainBg,
    "& textarea": {
      textAlign: "center",
    },
    "& *": {
      color: theme.palette.text.secondary,
    },
  },
  download: {
    background: theme.palette.mainBg,
    "&:hover": {
      background: theme.palette.mainBg,
    },
  },
  downloadIcon: {
    color: "#fff",
    marginLeft: theme.spacing(1),
  },
  headerCell: {
    color: "rgba(255, 255, 255, 0.6)",
  },
  row: {
    background: theme.palette.mainBg,
    border: "none",
    color: "#fff",
    "& td:first-child": {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    "& td:last-child": {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
  },
  cell: {
    color: "#fff",
    paddingBottom: "1em",
  },
  container: {
    background: theme.palette.mainBg,
    borderRadius: "15px",
  },
  title: {
    background: `linear-gradient(to right, ${theme.palette.gradientLight}, ${theme.palette.gradientDefault})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "bold",
  },
  applyFiltersBtn: {
    background: `linear-gradient(to right, ${theme.palette.gradientLight}, ${theme.palette.gradientDefault})`,
    color: "#fff",
    fontWeight: "bold",
  },
  advancedFilterBtnDefault: {
    background: theme.palette.mainBg,
    color: "#fff",
    fontWeight: "bold",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
  },
  label: {
    color: "#fff",
    paddingBottom: theme.spacing(1),
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 15px",
  },
  totalDataLabel: {
    fontSize: "18px",
    color: "#fff",
    lineHeight: "80px",
  },
  totalDataValue: {
    fontSize: "50px",
    color: "#fff",
    lineHeight: "80px",
  },
}));

const TransactionReport: StatelessFunctionalComponent<Props> = ({
  data,
  totalData,
  page,
  total,
  pageSize,
  filters,
  loading,
  onChangeTablePage,
  onChangeTableFilters,
  onChangeTablePageSize,
  onGetData,
  onDownloadTransactionReport,
  departmentsDropdown,
  countriesDropdown,
  customersDropdown,
  platformsDropdown,
  agesDropdown,
  onGetCountriesDropdownData,
  onGetPlatformsDropdownData,
  onGetAgesDropdownData,
  setIsAdvancedFilterEnabled,
  isAdvancedFilterEnabled,
  fields,
}: Props) => {
  const classes = useStyles();

  useEffect(() => {
    if (data.length === 0) {
      onGetData();
    }
  }, []);

  useEffect(() => {
    onGetCountriesDropdownData();
    onGetPlatformsDropdownData();
    onGetAgesDropdownData();
  }, []);

  useEffect(() => {
    setQueryFilter(filters);
  }, [filters]);

  const columns: Array<Column<ItemData>> = [
    {
      label: "Date",
      field: "date",
      key: "date",
    },
    {
      label: "Company Name",
      field: "customer",
      key: "customer",
    },
    {
      label: "Transaction Id",
      field: "invoiceId",
      key: "invoiceId",
    },
    {
      label: "Department",
      field: "department",
      key: "department",
    },
    {
      label: "Country",
      field: "country",
      key: "country",
    },
    {
      label: "Platform",
      field: "platform",
      key: "platform",
    },
    {
      label: "Age Group",
      field: "age",
      key: "age",
    },
    {
      label: "Leads",
      field: "quantity",
      key: "quantity",
    },
    {
      label: "Commission",
      field: "cost",
      key: "cost",
    },
    {
      label: "Total Commission",
      field: "amount",
      key: "amount",
    },
  ].filter((column: Column<ItemData>): boolean => fields.includes(column.field));

  const totalColumns: Array<Column<TotalData>> = [
    {
      label: "",
      field: "",
      key: "summary",
      className: classes.totalDataLabel,
      render: (): string => "Summary",
    },
    {
      label: "",
      field: "",
      key: "quantity",
      className: classes.totalDataValue,
      render: ({ row }: { row: TotalData }): string => `${String(filters.currency) || "$"} ${row.amount}`,
      colSpan: columns.length - 8,
    },
  ];

  const isShowData: boolean = data.length > 0 && !loading;
  const isShowNoDataMessage: boolean = data.length === 0 && !loading;

  const handleFiltersChange = (newFilters: Filters) => {
    onChangeTableFilters(TRANSACTION_TABLE, newFilters);
  };

  const handlePageChange = (newPage: number) => {
    onChangeTablePage(TRANSACTION_TABLE, newPage);
    onGetData();
  };

  const handlePageSizeChange = (newPageSize: number) => {
    onChangeTablePage(TRANSACTION_TABLE, 1);
    onChangeTablePageSize(TRANSACTION_TABLE, newPageSize);
    onGetData();
  };

  const handleRangeChangeDateRangePicker = ({ startDate, endDate }) => {
    onChangeTableFilters(TRANSACTION_TABLE, { dateFrom: startDate, dateTo: endDate });
  };

  const dynamicFieldsOptions: DropDownObjItemType[] = [
    { value: "customer", label: "Company Name" },
  ];
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Box width={1} pb={2} pt={3} pl={2} pr={2} display="flex" justifyContent="space-between">
          <Typography variant="h5" className={classes.title}>Transaction Report</Typography>
          <Box display="flex">
            <Box pt={1} pr={2}>
              <Typography variant="inherit" className={classes.label}>Export Report</Typography>
            </Box>
            <Button
              className={classes.download}
              variant="contained"
              color="primary"
              name="download"
              disabled={loading}
              startIcon={<GetApp className={classes.downloadIcon} />}
              onClick={onDownloadTransactionReport}
            />
          </Box>
        </Box>
        <Box display="flex" pb={2} pt={3} pl={2} pr={2}>
          <Box flexGrow={1}>
            <FilterBuilder
              data-testid="filters"
              WrapperItemComponent={FilterWrapper}
              // $FlowFixMe
              filters={filters}
              onFiltersChange={handleFiltersChange}
              items={[
                {
                  type: "select",
                  filterKey: "department",
                  filterProps: {
                    label: "Department",
                    className: classes.input,
                    classes: { formLabel: classes.label, root: classes.whiteBg },
                    disabled: loading,
                    "data-testid": "filter-department",
                    options: [
                      { value: "", label: "All" },
                      ...departmentsDropdown,
                    ].map(mapOptions),
                  },
                },
                {
                  type: "select",
                  filterKey: "country",
                  filterProps: {
                    label: "Country",
                    className: classes.input,
                    classes: { formLabel: classes.label, root: classes.whiteBg },
                    disabled: loading,
                    "data-testid": "filter-country",
                    options: [
                      { value: "", label: "All" },
                      ...countriesDropdown,
                    ].map(mapOptions),
                  },
                },
                {
                  type: "select",
                  filterKey: "customer",
                  filterProps: {
                    label: "Company Name",
                    className: classes.input,
                    classes: { formLabel: classes.label, root: classes.whiteBg },
                    disabled: loading,
                    "data-testid": "filter-customer",
                    options: [
                      { value: "", label: "All" },
                      ...customersDropdown,
                    ].map(mapOptions),
                  },
                },
                {
                  type: "textField",
                  filterKey: "invoiceId",
                  filterProps: {
                    label: "Transaction id",
                    className: classes.input,
                    classes: { formLabel: classes.label, root: classes.whiteBg },
                    disabled: loading,
                    type: "number",
                    "data-testid": "filter-invoiceId",
                  },
                },
                {
                  type: "select",
                  filterKey: "platform",
                  filterProps: {
                    label: "Platform",
                    className: classes.input,
                    classes: { formLabel: classes.label, root: classes.whiteBg },
                    disabled: loading,
                    "data-testid": "filter-platform",
                    options: [
                      { value: "", label: "All" },
                      ...platformsDropdown,
                    ].map(mapOptions),
                  },
                },
                {
                  type: "select",
                  filterKey: "age",
                  filterProps: {
                    label: "Age Group",
                    className: classes.input,
                    classes: { formLabel: classes.label, root: classes.whiteBg },
                    disabled: loading,
                    "data-testid": "filter-age",
                    options: [
                      { value: "", label: "All" },
                      ...agesDropdown,
                    ].map(mapOptions),
                  },
                },
                {
                  type: "dateRangePicker",
                  filterKey: "dateRangePicker",
                  filterProps: {
                    disabled: loading,
                    classes: {
                      input: {
                        root: classes.dateRange,
                      },
                      formLabel: classes.label,
                    },
                    label: "Time period",
                    startDate: filters.dateFrom,
                    endDate: filters.dateTo,
                    onChangeDate: handleRangeChangeDateRangePicker,
                    onChangePreset: handleRangeChangeDateRangePicker,
                    presets,
                    locale: en,
                    formatDate: (date) => dayjs(date).format("DD-MM-YYYY"),
                    "data-testid": "filter-date-range-picker",
                  },
                },
              ]}
            />
            { isAdvancedFilterEnabled && (
              <Box>
                <FilterBuilder
                  WrapperItemComponent={FilterWrapper}
                  // $FlowFixMe wrong type
                  filters={filters}
                  onFiltersChange={handleFiltersChange}
                  items={[
                    {
                      type: "select",
                      filterKey: "currency",
                      filterProps: {
                        label: "Conversion currency",
                        className: classes.input,
                        classes: { formLabel: classes.label, root: classes.whiteBg },
                        disabled: loading,
                        "data-testid": "filter-currency",
                        disableClearable: true,
                        options: [
                          { value: "$", label: "Only in $" },
                          { value: "€", label: "Only in €" },
                          { value: "£", label: "Only in £" },
                        ].map(mapOptions),
                      },
                    },
                    {
                      type: "multiSelect",
                      filterKey: "dynamicFields",
                      filterProps: {
                        label: "Show columns",
                        className: classes.dateRange,
                        classes: { formLabel: classes.label, option: classes.advancedFilterBtnDefault },
                        disabled: loading,
                        "data-testid": "filter-dynamicFields",
                        options: dynamicFieldsOptions.map(mapOptions),
                      },
                    },
                  ]}
                />
              </Box>
            )}
          </Box>
          <Box alignSelf="flex-start" display="flex" pt={3} flexShrink={0}>
            <Box pr={1}>
              <Button
                variant="contained"
                className={isAdvancedFilterEnabled ? classes.applyFiltersBtn : classes.advancedFilterBtnDefault}
                name="advancedFilter"
                onClick={() => {
                  setIsAdvancedFilterEnabled(!isAdvancedFilterEnabled);
                }}
              >
                Advanced filter
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                className={classes.applyFiltersBtn}
                name="applyFilters"
                disabled={loading}
                onClick={onGetData}
              >
                Apply filters
              </Button>
            </Box>
          </Box>
        </Box>
        { loading && (
          <Grid container justifyContent="center">
            <Box pb={3}>
              <CircularProgress />
            </Box>
          </Grid>
        )}
        { isShowNoDataMessage && (
          <Grid container justifyContent="center" className={classes.whiteBg}>
            <Box pb={3}>
              <Typography>Nothing to show</Typography>
            </Box>
          </Grid>
        )}
        { isShowData && (
          <Box pb={2} pr={2} pl={2}>
            <TableComponent
              classes={{
                headerCell: classes.headerCell,
                row: classes.row,
                cell: classes.cell,
                table: classes.table,
              }}
              data={data}
              columns={columns}
              totalData={totalData}
              totalColumns={totalColumns}
            />
            <TablePagination
              data-testid="pagination"
              page={page}
              pageSize={pageSize}
              count={total}
              onChangePage={handlePageChange}
              onChangePageSize={handlePageSizeChange}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default TransactionReport;
