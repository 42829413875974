// @flow
import React, { type Node } from "react";
import {
  TableCell, TableHead, TableRow, TableSortLabel,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import type {
  Column,
  HeadProps,
  HeadClasses,
} from "./types/TableComponent.types";

const useStyles = makeStyles((theme) => ({
  headerRow: {
    background: "transparent",
  },
  headerCell: {
    textAlign: "center",
    color: theme.palette.text.subtitle,
  },
  collapseIcon: {
    width: "48px",
  },
}));

function Head<T>({
  columns,
  onChangeSorting,
  sorting = {},
  classes = {},
}: HeadProps<T>): Node {
  const ownClasses: HeadClasses = useStyles();

  return (
    <TableHead className={clsx(ownClasses.headerRow, classes.headerRow)}>
      <TableRow>
        {columns.map((column: Column<T>): Node => (
          column.sortable ? (
            <TableCell
              key={column.key}
              size="small"
              className={clsx(ownClasses.headerCell, classes.headerCell, column.className)}
              onClick={
                column.sortable && onChangeSorting
                  ? (): void => {
                    const { key } = column;
                    if (!sorting[key]) {
                      return onChangeSorting({ [key]: "asc" });
                    }
                    if (sorting[key] === "asc") {
                      return onChangeSorting({ [key]: "desc" });
                    }
                    if (sorting[key] === "desc") {
                      return onChangeSorting({});
                    } return onChangeSorting({});
                  }
                  : null
              }
            >
              <TableSortLabel
                direction={sorting[column.key]}
                active={Boolean(sorting[column.key])}
                hideSortIcon={column.sortable !== true}
              >
                {typeof column.label === "function"
                  ? column.label({ column })
                  : column.label}
              </TableSortLabel>

            </TableCell>
          ) : (

            <TableCell
              key={column.key}
              size="small"
              className={clsx(ownClasses.headerCell, classes.headerCell, column.className)}
            >
              {typeof column.label === "function"
                ? column.label({ column })
                : column.label}
            </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}

export default Head;
